<template>
    <section class="tt-center">
        <Nav
            class="mt-5 mb-4"
            route-name="TechnologyTransfer"
            parent-page-name="Трансфер технологий"
            current-page-name="ЦТТ Университета ИТМО"
        />

        <div class="tt-center__main-img d-none d-xl-flex">
            <div class="tt-center__blurred-img mt-lg-auto ms-auto d-flex">
                <h2 class="tt-center__title mt-lg-auto">
                    {{ getcCttPageInfo('title') }}
                </h2>
                <img
                    src="../../assets/page-logo.svg"
                    class="d-block ms-auto mt-auto mb-3 d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <h2 class="tt-center__title tt-center__title--dark">
                {{ getcCttPageInfo('title') }}
            </h2>
        </div>

        <div class="tt-center-about d-flex flex-column flex-lg-row align-items-center">
            <div class="col-12 col-lg-8 col-xl-6 d-flex justify-content-center justify-content-lg-start">
                <div class="nccr-about__img-wrapper">
                    <img
                        class="task__img"
                        src="../../assets/views/tt_center/img_1.svg"
                        alt=""
                    >
                </div>
            </div>

            <div class="col-12 col-lg-4 col-xl-6 d-flex flex-column align-items-center align-items-lg-start mt-4 mt-lg-0">
                <h3 class="nccr-about__title mt-2">{{ getcCttPageInfo('subtitle_1') }}</h3>
                <div class="nccr-about__text me-xl-5 mt-3">
                    {{ getcCttPageInfo('subtext_1') }}
                </div>
            </div>
        </div>

        <div class="tt-center-stucture">
            <div class="tt-center-stucture__title">{{ getcCttPageInfo('subtitle_2') }}</div>

            <div class="d-flex flex-column justify-content-center flex-xl-row mt-xl-5">
                <div class="overflow-auto">
                    <img
                        class="tt-center__scheme1"
                        src="../../assets/views/tt_center/scheme_1.svg"
                        alt=""
                    >
                </div>

                <div class="col-xl-3 d-flex justify-content-center justify-content-xl-start mt-4 mt-xl-0">
                    <div class="tt-center__report tt-center-report ms-xl-4 me-0">
                        <div>
                            {{ getcCttReportWidgetInfo('text') }}
                        </div>

                        <div class="tt-center-report-card mt-4 p-4" style="height: 135px">
                          <div class="tt-center-report-card__text">Отчет о реализации программы ЦТТ</div>
                          <div class="tt-center-report-card__download">
                            <ul class="bg-transparent border-0 mt-2 p-0">
                              <li class="list-group">
                                <a class="bg-transparent" href="/media/widgets/%D0%9E%D1%82%D1%87%D0%B5%D1%82_%D0%BE_%D1%80%D0%B5%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8_%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D1%8B_%D0%A6%D0%A2%D0%A2.pdf" target="_blank">
                                  Отчет 2021
                                  <img
                                      class="mx-1 mb-1"
                                      src="../../assets/views/tt_center/arrow-down.svg"
                                      alt=""
                                  >
                                </a>
                              </li>
                              <li class="list-group mt-2">
                                <a class="bg-transparent" href="/media/storage/Отчет_по_программе_ЦТТ_2022.pdf" target="_blank">
                                  Отчет 2022
                                  <img
                                      class="mx-1 mb-1"
                                      src="../../assets/views/tt_center/arrow-down.svg"
                                      alt=""
                                  >
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="tt-center-report-card mt-4 p-4" style="height: 160px">
                            <div class="tt-center-report-card__text">Политика трансфера технологий</div>

                            <div class="tt-center-report-card__download">
                                <ul class="bg-transparent border-0 mt-2 p-0">
                                    <li class="list-group">
                                        <a class="bg-transparent" href="https://tt.itmo.ru/media/ttc-politics/Политика трансфера технологий. Приказ № 1593-од от 29.12.2021.pdf" target="_blank">
                                            Приказ о политике трансфера технологий
                                            <img
                                                class="mx-1 mb-1"
                                                src="../../assets/views/tt_center/arrow-down.svg"
                                                alt=""
                                            >
                                        </a>
                                    </li>
                                    <li class="list-group mt-2">
                                        <a class="bg-transparent" href="https://tt.itmo.ru/media/storage/Политика_трансфера_технологии.pdf" target="_blank">
                                            Политика трансфера технологий
                                            <img
                                                class="mx-1 mb-1"
                                                src="../../assets/views/tt_center/arrow-down.svg"
                                                alt=""
                                            >
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="tt-center-report-card mt-4 p-4">
                            <div class="tt-center-report-card__text">Приказ о документах по работе с РИД</div>
                            <div class="tt-center-report-card__download d-flex align-items-center mt-1">
                                <a href="https://tt.itmo.ru/media/ttc-politics/Приказ № 1594-од от 29.12.2021.PDF" target="_blank">Скачать приказ</a>

                                <img
                                    class="d-block ms-2"
                                    src="../../assets/views/tt_center/arrow-down.svg"
                                    alt=""
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tt-center-goal-block d-flex flex-column flex-lg-row align-items-center py-5">
            <div class="col-12 col-lg-6 d-flex justify-content-center">
                <div class="tt-center-goal__img d-flex justify-content-center">
                    <img
                        class="opportunities__img"
                        src="../../assets/views/tt_center/goal.svg"
                        alt=""
                    >
                </div>
            </div>

            <div class="col-12 col-lg-6 d-flex flex-column align-items-center align-items-lg-start mt-4 mt-lg-0">
                <h3 class="tt-center-goal__title mx-3 text-center text-lg-start">
                    {{ getcCttPageInfo('subtitle_3') }}
                </h3>

                <div class="tt-center-goal__text mt-3 mx-3">
                    {{ getcCttPageInfo('subtext_3') }}
                </div>
            </div>
        </div>

        <div class="tt-center-process">
            <div class="tt-center-process__title">{{ getcCttPageInfo('subtitle_4') }}</div>

            <div class="d-flex justify-content-center mt-5">
                <div class="overflow-auto">
                    <img
                        class="tt-center__scheme2"
                        src="../../assets/views/tt_center/transfer-scheme.svg"
                        alt=""
                    >
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";
import {computed} from "vue";
import { useStore } from "vuex";

export default {
    name: "TTCenter",

    components: {
        Nav
    },

    setup() {
        const store = useStore()

        store.dispatch('widgets/getMainPageWidgets')
        const mainPageWidgets = computed(() => store.getters['widgets/mainPageWidgets'])

        const getCttLink = computed(() => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.ctt.report_file
            }

            return ''
        })

        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('pages/getCttPage')
        const cttPage = computed(() => store.getters['pages/cttPage'])

        const getcCttPageInfo = (key) => {
            if (cttPage.value) {
                return cttPage.value[key]
            }

            return ''
        }

        const getcCttReportWidgetInfo = (key) => {
            if (cttPage.value) {
                return cttPage.value.report_widget[key]
            }

            return ''
        }

        return {
            getCttLink,
            getcCttPageInfo,
            getcCttReportWidgetInfo,
        }
    }
}
</script>
